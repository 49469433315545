import {
    SimplePageContentWrapper,
    SimplePageLayout
} from '@flexinet/ui-components';

import {IndexPageHeader} from '../components/IndexPageHeader';
import {IndexPageNavigation} from "../navigation/IndexPageNavigation";
import {RecordsTable} from "../tables/RecordsTable.tsx";
import {useMessages} from "../hooks/useMessages.ts";
import {useEffect} from "react";
import {useParams} from "react-router-dom";


export const IndexPage = () => {

    const {status} = useParams()

    const {
        visibleItems,
        fetchRecords,
    } = useMessages();


    useEffect(() => {
        const query = {} as { [k: string]: string };
        if (status) {
            if (status === 'inbox') {
                query.status = 'UNREAD' as string
            } else if (status === 'read') {
                query.status = 'READ' as string
            }
        }
        fetchRecords(query);
    }, []);

    return (
        <SimplePageLayout
            pageNavigation={<IndexPageNavigation/>}
            pageHeader={<IndexPageHeader/>}
        >

            <SimplePageContentWrapper>
                <RecordsTable records={visibleItems}/>
            </SimplePageContentWrapper>

        </SimplePageLayout>
    );
};
