import {SimplePageLayout} from '@flexinet/ui-components';

import {IndexPageHeader} from '../components/IndexPageHeader';
import {IndexPageNavigation} from "../navigation/IndexPageNavigation";
import {RecordsTable} from "../tables/RecordsTable.tsx";
import {useMessages} from "../hooks/useMessages.ts";
import {useEffect} from "react";
import {useParams} from "react-router-dom";


export const AliasMessagesPage = () => {

    const {id: aliasId} = useParams()

    if (!aliasId) {
        throw new Error('Alias id is required')
    }

    const {
        visibleItems,
        fetchRecords,
    } = useMessages();


    useEffect(() => {
        fetchRecords({
                         aliasId: aliasId
                     });
    }, []);

    return (
        <SimplePageLayout
            pageNavigation={<IndexPageNavigation/>}
            pageHeader={<IndexPageHeader/>}
        >
            <RecordsTable records={visibleItems}/>
        </SimplePageLayout>
    );
};
