import {PlatformNavbar} from "@flexinet/ui-components";
import {getMenuItems} from "../data/menus";
import {useTags} from "../../../features/tags/hooks/useTags.ts";
import {useEffect} from "react";
import {Tag} from "../../../features/tags/types.ts";
import {AiOutlineTag} from "react-icons/ai";
import {IconContext} from "react-icons";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";

export const PortalNavbar = () => {

    const {allowedActions} = useVerifiedPermissions()

    const items = getMenuItems()


    const useMenuTags = false

    const {
        items: tags,
        fetchRecords
    } = useTags()

    useEffect(() => {
        fetchRecords()
    }, []);


    if (useMenuTags) {

        const emailMessagesLink = items.find(item => item.label === 'Email Messages')
        tags.forEach((tag: Tag) => {
            emailMessagesLink?.links?.push({
                                               id: tag.id,
                                               label: tag.name as string,
                                               link: `/messages/?tag=${tag.id}`,
                                               leftSection: <IconContext.Provider
                                                   value={{size: '1.6em'}}><AiOutlineTag/></IconContext.Provider>,
                                           })
        })

    }

    const menuItems = items.filter((item) => {
        return allowedActions.includes(item.rbac as string)
    });


    return <PlatformNavbar navbarHeader={'Flexi Email'} menuItems={menuItems}/>

};