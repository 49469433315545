import {Field} from 'formik';

import {
    FormikCreatableSelect,
    FormikTextInput,
    PlatformFieldset
} from '@flexinet/ui-components';


export const FormComponent = () => {

    return (
        <PlatformFieldset legend={'Message'}>

            <Field
                name='from'
                label='From'
                description='Please enter the message'
                data={[]}
                component={FormikCreatableSelect}
                withAsterisk
            />

            <Field
                name='to'
                label='To'
                description='Please enter the message'
                component={FormikCreatableSelect}
                withAsterisk
            />

            <Field
                name='subject'
                label='Subject'
                description='Please enter the subject'
                component={FormikTextInput}
                withAsterisk
            />

        </PlatformFieldset>
    );
};
