import {I18n} from '@aws-amplify/core';

import {
    ReloadButton,
    SimplePageHeader,
} from '@flexinet/ui-components';

import {Box,} from '@mantine/core';

import {useMessages} from '../hooks/useMessages';
import {DeleteRecordButton} from '../buttons/DeleteRecordButton.tsx';
import {AddRecordButton} from "../buttons/AddRecordButton.tsx";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";

const ComponentsReloadButton = () => {

    const {fetchRecords} = useMessages();
    return (
        <Box mx='s'>
            <ReloadButton onClick={() => fetchRecords({})}/>
        </Box>
    );
};


const useTools = () => {

    const {allowedActions} = useVerifiedPermissions()

    const tools = [<ComponentsReloadButton key='reload'/>];

    if (allowedActions.includes('DeleteMessage')) {
        tools.push(<DeleteRecordButton key='delete'/>);
    }

    if (allowedActions.includes('CreateMessage')) {
        tools.push(<AddRecordButton key='add'/>);
    }


    return tools;
};

export const IndexPageHeader = () => {
    return (
        <SimplePageHeader
            title={I18n.get('Messages')}
            tools={useTools()}
        />
    );
};