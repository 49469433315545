import {Field} from 'formik';

import {
    FormikTextInput,
    PlatformFieldset
} from '@flexinet/ui-components';


export const FormComponent = () => {

    return (
        <PlatformFieldset legend={'Contact'}>

            <Field
                name='name'
                label='Name'
                description='Please enter the application name'
                component={FormikTextInput}
                withAsterisk
            />

        </PlatformFieldset>
    );
};
