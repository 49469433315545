import {PlatformNavbarMenuItem} from "@flexinet/ui-components";
import {v4 as uuidv4} from 'uuid';


import {
    AiOutlineAim,
    AiOutlineHome,
    AiOutlineInbox,
    AiOutlineMail,
    AiOutlineRead,
    AiOutlineTag,
    AiOutlineTransaction,
    AiOutlineUser
} from 'react-icons/ai'
import {rbacActions} from "../../../config/rbac/actions.ts";

export const getMenuItems = () => {

    return [
        {
            id: uuidv4(),
            link: '/dashboard',
            label: 'Dashboard',
            leftSection: <AiOutlineHome/>,
            rbac: rbacActions.GetDashboard,
        }, {
            id: uuidv4(),
            link: '/messages',
            label: 'Email Messages',
            leftSection: <AiOutlineMail/>,
            rbac: rbacActions.ListMessages,
            defaultOpened: true,
            links: [{
                id: 'all',
                link: '/messages/status/inbox',
                label: 'Inbox',
                leftSection: <AiOutlineInbox/>,
                rbac: rbacActions.ListMessages,
            }, {
                id: 'read',
                link: '/messages/status/read',
                label: 'Read',
                leftSection: <AiOutlineRead/>,
                rbac: rbacActions.ListMessages,
            }],
        }, {
            id: uuidv4(),
            link: '/aliases',
            label: 'Aliases',
            leftSection: <AiOutlineAim/>,
            rbac: rbacActions.ListAliases,
        }, {
            id: uuidv4(),
            link: '/personas',
            label: 'Personas',
            leftSection: <AiOutlineUser/>,
            rbac: rbacActions.ListPersonas,
        }, {
            id: uuidv4(),
            link: '/tags',
            label: 'Tags',
            leftSection: <AiOutlineTag/>,
            rbac: rbacActions.ListTags,
        }, {
            id: uuidv4(),
            link: '/contacts',
            label: 'Contacts',
            leftSection: <AiOutlineUser/>,
            rbac: rbacActions.ListContacts,
        }, {
            id: uuidv4(),
            link: '/service-plans',
            label: 'Service Plans',
            leftSection: <AiOutlineTransaction/>,
            rbac: rbacActions.ListServicePlans,
        }] as PlatformNavbarMenuItem[];

};

