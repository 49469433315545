import {
    ReactElement,
    useEffect,
} from 'react';

import {Stack,} from '@mantine/core';

import {
    MantineDataTable,
    SimpleTableBatchAction,
} from '@flexinet/ui-components';
import {useTagsSelection} from '../hooks/useTagsSelection.ts';
import {useTags} from "../hooks/useTags.ts";
import {useTagsSearch} from "../hooks/useTagsSearch.ts";
import {useTagsPaging} from "../hooks/useTagsPaging.ts";
import {useAuth} from "../../../hooks/useAuth.ts";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";
import {useTagsTableColumns} from "../hooks/useTagsTableColumns.tsx";
import {Tag} from "../types.ts";
import {DataTableColumn} from "mantine-datatable";


export const RecordsTable = (
    {
        extraTools,
        settings,
    }: {
        extraTools?: ReactElement[],
        settings?: { label: string, onClick: () => void }[]
    }) => {


    const {
        user
    } = useAuth();


    if (!user) {
        throw new Error('user is required parameter')
    }


    const {allowedActions} = useVerifiedPermissions()

    const {
        visibleItems,
        loading,
        visibleCount,
        fetchRecords,
    } = useTags();

    const {
        columns,
    } = useTagsTableColumns();

    const {
        handleFilterByKeyword,
    } = useTagsSearch();

    const {
        limit,
        page,
        handleItemsPerPageChange,
        handlePageChange,
    } = useTagsPaging();


    const {
        selection,
        handleRowSelection,
        handleClearSelection,
    } = useTagsSelection();

    useEffect(() => {
        fetchRecords();
    }, []);


    let selectedRecords = undefined;
    if (allowedActions.includes(rbacActions.DeleteTag)) {
        selectedRecords = Object.keys(selection)
                                .map(key => selection[key])
    }

    return (
        <Stack gap={'lg'}>
            <SimpleTableBatchAction
                limit={limit}
                handleItemsPerPageChange={handleItemsPerPageChange}
                settings={settings}
                handleFilterByKeyword={handleFilterByKeyword}
                extraTools={extraTools}
            />


            <MantineDataTable
                columns={columns as DataTableColumn[]}
                records={visibleItems}
                totalRecords={parseInt(visibleCount)}
                recordsPerPage={parseInt(limit)}
                page={parseInt(page)}
                onPageChange={handlePageChange}
                paginationText={({
                                     from,
                                     to,
                                     totalRecords
                                 }) => `Records ${from} - ${to} of ${totalRecords}`}
                paginationSize={'md'}
                selectedRecords={selectedRecords}
                onSelectedRecordsChange={(items) => {
                    handleClearSelection();
                    items.forEach(item => {
                        handleRowSelection(item as unknown as Tag)
                    })
                }}
                fetching={loading}
            />

        </Stack>
    );
};
