import {useEffect} from 'react';

import {useParams} from 'react-router-dom';

import {Grid} from '@mantine/core';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
    SimplePageContentWrapper,
} from '@flexinet/ui-components';
import {Profile} from '../profile/Profile';

import {EditPageNavigation} from '../navigation/EditPageNavigation';
import {useAlias} from '../hooks/useAlias';
import {useEditPageTools} from '../hooks/useEditPageTools';
import {useAliasesSelection} from "../hooks/useAliasesSelection.ts";
import {AliasMessagesPage} from '../../messages/pages/AliasMessagesPage.tsx';


export const EditPage = () => {

    const {id: aliasId} = useParams();

    if (typeof aliasId === 'undefined') {
        throw new Error('aliasId is required parameter');
    }

    const {
        record: alias,
        fetchRecord,
        updateRecord,
    } = useAlias(aliasId);

    const {handleClearSelection} = useAliasesSelection();

    const {
        mode,
        tools,
    } = useEditPageTools();


    useEffect(() => {
        handleClearSelection();
    }, []);

    useEffect(() => {
        fetchRecord();
    }, [aliasId]);

    if (!alias) {
        return <PlatformLoader message='Loading alias...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={alias.name ? alias.name : alias.id}
            pageNavigation={<EditPageNavigation component={alias}/>}
            tools={tools}
        >


            {mode === 'view' ?
                <Grid>
                    <Grid.Col span={{
                        base: 4,
                        sm: 12
                    }}>
                        <Profile record={alias}/>
                    </Grid.Col>
                    <Grid.Col span={{
                        base: 8,
                        sm: 12
                    }}>
                        <AliasMessagesPage/>
                    </Grid.Col>
                </Grid>
                :
                <SimplePageContentWrapper>
                    <RecordForm
                        record={alias}
                        handleSubmit={updateRecord}
                    />
                </SimplePageContentWrapper>
            }


        </FormPageLayout>
    );
};
