import {Formik} from 'formik';

import {FormComponent} from './FormComponent';

import {RecordSchema} from './RecordSchema';

import {FormWithButtons} from '@flexinet/ui-components';

import {Persona} from '../types';
import {useNavigate} from "react-router-dom";
import {stage} from "../../../config/stage";

export const RecordForm = ({
                               record,
                               handleSubmit,
                           }: {
    record: Omit<Persona, 'id'>,
    handleSubmit: (values: Persona) => void
}) => {
    const navigate = useNavigate()

    const onSubmit = (values: Persona & { generationMethod?: string }) => {
        const {
            generationMethod,
            ...rest
        } = values

        console.debug('removing gen method', generationMethod);

        handleSubmit(rest);
        navigate('/personas');
    };

    const onCancel = () => {
        navigate('/personas');
    };


    let initialValues = {
        status: 'ENABLED',
        generationMethod: 'auto'
    } as Persona & { generationMethod: string };

    if (record) {
        initialValues = {...initialValues, ...record};
    }


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={RecordSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleSubmit,
                  isSubmitting,
                  ...rest
              }) => (
                <FormWithButtons handleSubmit={handleSubmit} isSubmitting={isSubmitting}
                                 handleCancel={onCancel} debug={false && stage === 'ide'} {...rest}  >
                    <FormComponent handleSubmit={handleSubmit} isSubmitting={isSubmitting} {...rest} />
                </FormWithButtons>)}
        </Formik>
    );
};
