import {Formik} from 'formik';

import {FormComponent} from './FormComponent';

import {RecordSchema} from './RecordSchema';

import {FormWithButtons} from '@flexinet/ui-components';

import {Alias} from '../types';
import {useNavigate} from "react-router-dom";
import {stage} from "../../../config/stage";

export const RecordForm = ({
                               record,
                               handleSubmit,
                           }: {
    record: Omit<Alias, 'id'>,
    handleSubmit: (values: Alias) => void
}) => {
    const navigate = useNavigate()

    const onSubmit = (values: Alias & { generationMethod: string }) => {

        const {
            generationMethod,
            ...rest
        } = values

        console.debug('removing gen method', generationMethod);


        handleSubmit(rest);
        navigate('/aliases');
    };

    const onCancel = () => {
        navigate('/aliases');
    };


    let initialValues = {
        status: 'Enabled',
        generationMethod: 'auto'
    } as Alias & { generationMethod: string };

    if (record) {
        initialValues = Object.assign({generationMethod: 'auto'}, initialValues, record);
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={RecordSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleSubmit,
                  isSubmitting,
                  ...rest
              }) => (
                <FormWithButtons handleSubmit={handleSubmit} isSubmitting={isSubmitting}
                                 handleCancel={onCancel} debug={false && stage === 'ide'} {...rest}  >
                    <FormComponent {...rest} />
                </FormWithButtons>)}
        </Formik>
    );
};
