import {useAppDispatch} from '../../../hooks/useTypedReactRedux';
import {
    addTableColumn,
    setTableColumns
} from "../reducers";
import {I18n} from "@aws-amplify/core";
import {Tag} from "../types.ts";
import {NavLink} from "react-router-dom";
import {formatTimestamp} from "../../../utils/TableTimestamps.tsx";
import {DataTableColumn} from "mantine-datatable";


export const useTagsTableColumns = () => {

    const dispatch = useAppDispatch();

    const columns: DataTableColumn<Tag>[] = [
        {
            accessor: 'name',
            title: I18n.get('Name'),
            width: '1fr',
            render: (item) => <NavLink to={`/tags/${item.id}`}>{item.name}</NavLink>,
        },
        {
            accessor: 'description',
            title: I18n.get('Description'),
            width: '1fr',
            render: (item) => item.description,
        },
        {
            accessor: 'created',
            title: I18n.get('Created'),
            width: '1fr',
            render: (item) => formatTimestamp(item.created as string),
        },
    ];

    // FETCH
    const handleAddTableColumn = (column: object) => {
        dispatch(addTableColumn(column));
    };

    const handleSetTableColumns = (columns: object[]) => {
        dispatch(setTableColumns(columns));
    };


    return {
        columns,
        handleAddTableColumn,
        handleSetTableColumns,
    };
};
