import {Field} from 'formik';

import {
    FormikTextarea,
    FormikTextInput,
    PlatformFieldset
} from '@flexinet/ui-components';
import {Tag} from "../types.ts";


export const FormComponent = ({
                                  values,
                              }: { values: Tag }) => {

    const editing = values.id !== undefined;

    return (
        <PlatformFieldset legend={'Tag'}>

            <Field
                name='name'
                label='Name'
                description='Please enter the tag. e.g. "godaddy"'
                component={FormikTextInput}
                withAsterisk
                disabled={editing}
                my={'xl'}
            />

            <Field
                name='description'
                label='Description'
                description='Please enter description'
                component={FormikTextarea}
                my={'xl'}
            />


        </PlatformFieldset>
    );
};

