import {
    ReactElement,
    useEffect,
} from 'react';

import {Stack,} from '@mantine/core';

import {
    MantineDataTable,
    SimpleTableBatchAction,
} from '@flexinet/ui-components';
import {usePersonasSelection} from '../hooks/usePersonasSelection';
import {usePersonas} from "../hooks/usePersonas.ts";
import {usePersonasSearch} from "../hooks/usePersonasSearch.ts";
import {usePersonasPaging} from "../hooks/usePersonasPaging.ts";
import {useAuth} from "../../../hooks/useAuth.ts";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {usePersonasTableColumns} from "../hooks/usePersonasTableColumns.tsx";
import {Persona} from "../types.ts";
import {DataTableColumn} from "mantine-datatable";


export const RecordsTable = ({
                                 extraTools,
                                 settings,
                             }: {
    extraTools?: ReactElement[],
    settings?: { label: string, onClick: () => void }[]
}) => {


    const {
        user
    } = useAuth();


    if (!user) {
        throw new Error('user is required parameter')
    }

    // const cognitoUsername = user.username;

    const {allowedActions} = useVerifiedPermissions()


    const {
        visibleItems,
        visibleCount,
        fetchRecords,
    } = usePersonas();

    const {
        columns,
    } = usePersonasTableColumns();

    const {
        handleFilterByKeyword,
    } = usePersonasSearch();

    const {
        page,
        limit,
        handleItemsPerPageChange,
        handlePageChange,
    } = usePersonasPaging();


    const {
        selection,
        handleRowSelection,
        handleClearSelection,
    } = usePersonasSelection();

    useEffect(() => {
        fetchRecords();
    }, []);


    let selectedRecords = [] as Persona[];
    if (allowedActions.includes('DeletePersona')) {
        selectedRecords = Object.keys(selection)
                                .map(key => selection[key])
    }


    return (
        <Stack gap={'lg'}>
            <SimpleTableBatchAction
                limit={limit}
                handleItemsPerPageChange={handleItemsPerPageChange}
                settings={settings}
                handleFilterByKeyword={handleFilterByKeyword}
                extraTools={extraTools}
            />

            <MantineDataTable
                columns={columns as DataTableColumn[]}
                records={visibleItems}
                totalRecords={parseInt(visibleCount)}
                recordsPerPage={parseInt(limit)}
                page={parseInt(page)}
                onPageChange={handlePageChange}
                paginationText={({
                                     from,
                                     to,
                                     totalRecords
                                 }) => `Records ${from} - ${to} of ${totalRecords}`}
                paginationSize={'md'}
                selectedRecords={selectedRecords}
                onSelectedRecordsChange={(items) => {
                    handleClearSelection();
                    items.forEach(item => {
                        handleRowSelection(item as unknown as Persona)
                    })
                }}
            />


        </Stack>
    );
};