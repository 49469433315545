import {
    Field,
    FormikProps
} from 'formik';


import {
    Box,
    Group,
    Stack
} from '@mantine/core';

import {
    FlexiButton,
    FormikRadioCardGroup,
    FormikRadioGroup,
    FormikTextarea,
    FormikTextInput,
    PlatformFieldset
} from '@flexinet/ui-components';
import {Persona} from "../types.ts";
import {AiOutlineReload} from "react-icons/ai";
import {useRandomUserGenerator} from "../../../hooks/useRandomUserGenerator.ts";


export const FormComponent = (props: FormikProps<Persona & { generationMethod: string }>) => {

    const values = props.values as Persona & { generationMethod: 'auto' | 'manual' };
    const editing = props.values.id !== undefined;

    const {generateRandomUser} = useRandomUserGenerator()

    return (
        <Stack gap={'xl'}>
            <PlatformFieldset legend={'Persona Details'}>

                <Field
                    name='generationMethod'
                    label='Generation Method'
                    component={FormikRadioCardGroup}
                    data={[{
                        label: 'Auto',
                        description: 'Auto generated name',
                        value: 'auto'
                    }, {
                        label: 'Manual',
                        description: 'Please enter the persona. E.g. "john.doe". The email address will be givenName.lastName@flexi.email',
                        value: 'manual'
                    }]}
                    disabled={editing}
                />


                {values.generationMethod === 'auto' &&

                    <Group gap={'xl'} my={'xl'} justify={'flex-start'} grow>
                        <Field
                            name='givenName'
                            label='Given Name'
                            description='Given Name'
                            component={FormikTextInput}
                        />
                        <Field
                            name='familyName'
                            label='Family Name'
                            description='Family Name'
                            component={FormikTextInput}
                        />

                        <Field
                            name='email'
                            label='Email'
                            description='Email'
                            component={FormikTextInput}
                        />

                        <Box pt={'2.8em'}>
                            <FlexiButton
                                fullWidth={true}
                                leftSection={<AiOutlineReload/>}
                                onClick={() => {
                                    const user = generateRandomUser();
                                    props.setFieldValue('givenName', user.firstName);
                                    props.setFieldValue('familyName', user.lastName);
                                    props.setFieldValue('email', user.email);
                                }}
                                disabled={editing ? true : false}
                            >Generate</FlexiButton>
                        </Box>

                    </Group>
                }

                {values.generationMethod === 'manual' &&
                    <Group gap={'xl'} my={'xl'} grow>

                        <Field
                            name='givenName'
                            label='Given Name'
                            description='Please enter the persona given name. E.g. "john doe". The email address will be john.doe@flexi.email'
                            component={FormikTextInput}
                            withAsterisk
                            disabled={editing}
                        />

                        <Field
                            name='familyName'
                            label='Family Name'
                            description='Please enter the persona family name'
                            component={FormikTextInput}
                            withAsterisk
                            disabled={editing}
                        />
                    </Group>
                }

            </PlatformFieldset>

            <PlatformFieldset legend={'Note'}>

                <Field
                    name='Note'
                    label='Note'
                    description='Please enter description'
                    component={FormikTextarea}
                />

            </PlatformFieldset>

            <PlatformFieldset legend={'Status'}>

                <Field
                    name='status'
                    label='Status'
                    data={[{
                        label: 'Enabled',
                        value: 'ENABLED'
                    }, {
                        label: 'Disabled',
                        value: 'DISABLED'
                    }]}
                    component={FormikRadioGroup}
                />


            </PlatformFieldset>
        </Stack>
    );
};
