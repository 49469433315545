import {SimplePageLayout} from '@flexinet/ui-components';

import {IndexPageHeader} from '../components/IndexPageHeader';
import {IndexPageNavigation} from "../navigation/IndexPageNavigation";
import {RecordsTable} from "../tables/RecordsTable.tsx";
import {useMessages} from "../hooks/useMessages.ts";
import {useEffect} from "react";
import {useParams} from "react-router-dom";


export const TagMessagesPage = () => {

    const {id: tagId} = useParams()

    if (!tagId) {
        throw new Error('Tag is required')
    }


    const {
        visibleItems,
        fetchRecords,
    } = useMessages();


    useEffect(() => {
        fetchRecords({
                         tagId: tagId
                     });
    }, []);

    return (
        <SimplePageLayout
            pageNavigation={<IndexPageNavigation/>}
            pageHeader={<IndexPageHeader/>}
        >
            <RecordsTable records={visibleItems}/>
        </SimplePageLayout>
    );
};
