import {ReactElement,} from 'react';

import {Stack} from '@mantine/core';

import {useMessages} from '../hooks/useMessages';

import {
    MantineDataTable,
    SimpleTableBatchAction,
} from '@flexinet/ui-components';
import {useMessagesSelection} from '../hooks/useMessagesSelection';
import {Message,} from '../types';
import {useMessagesSearch} from "../hooks/useMessagesSearch.ts";
import {useMessagesPaging} from "../hooks/useMessagesPaging.ts";
import {useMessagesTableColumns} from "../hooks/useMessagesTableColumns.tsx";
import {useAuth} from "../../../hooks/useAuth.ts";
import classes from './RecordsTable.module.css';
import {useNavigate} from "react-router-dom";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {DataTableColumn} from "mantine-datatable";


export const RecordsTable = (
    {
        records,
        extraTools,
        settings,
    }: {
        records?: Message[]
        extraTools?: ReactElement[],
        settings?: { label: string, onClick: () => void }[]
    }) => {

    const navigate = useNavigate()


    const {
        user
    } = useAuth();


    if (!user) {
        throw new Error('user is required parameter')
    }

    const {allowedActions} = useVerifiedPermissions()

    const {
        visibleCount,
    } = useMessages();


    const {
        columns,
    } = useMessagesTableColumns();

    const {
        handleFilterByKeyword,
    } = useMessagesSearch();

    const {
        limit,
        page,
        handleItemsPerPageChange,
        handlePageChange,
    } = useMessagesPaging();

    const {
        selection,
        handleRowSelection,
        handleClearSelection,
    } = useMessagesSelection();


    // const {
    //     updateRecord,
    // } = useMessage(selectedRecord?.id);


    let selectedRecords = [] as Message[];
    if (allowedActions.includes('DeleteMessage')) {
        selectedRecords = Object.keys(selection)
                                .map(key => selection[key])
    }


    return (
        <Stack>
            <SimpleTableBatchAction
                limit={limit}
                handleItemsPerPageChange={handleItemsPerPageChange}
                settings={settings}
                handleFilterByKeyword={handleFilterByKeyword}
                extraTools={extraTools}
            />


            <MantineDataTable
                columns={columns as DataTableColumn[]}
                records={records}
                totalRecords={parseInt(visibleCount)}
                recordsPerPage={parseInt(limit)}
                page={parseInt(page)}
                onPageChange={handlePageChange}
                paginationText={({
                                     from,
                                     to,
                                     totalRecords
                                 }) => `Records ${from} - ${to} of ${totalRecords}`}
                paginationSize={'md'}
                selectedRecords={selectedRecords}
                onSelectedRecordsChange={(items) => {
                    handleClearSelection();
                    items.forEach(item => {
                        handleRowSelection(item as unknown as Message)
                    })
                }}
                onRowClick={({
                                 record,
                             }) => {

                    navigate('/messages/' + record.id);

                    // if (record.status === 'UNREAD') {
                    //     updateRecord({...record, ...{status: 'READ'}});
                    // }

                }}
                rowClassName={(record) => {
                    if (record.status === 'UNREAD') {
                        return classes.unread;
                    } else if (record.status === 'READ') {
                        return classes.read;
                    }
                }}
            />

        </Stack>
    );
};
