import {useAppDispatch} from '../../../hooks/useTypedReactRedux';
import {
    addTableColumn,
    setTableColumns
} from "../reducers";
import {DataTableColumn} from "mantine-datatable";
import {Persona} from "../types.ts";
import {I18n} from "@aws-amplify/core";
import {NavLink} from "react-router-dom";
import {
    Badge,
    Group
} from "@mantine/core";
import {FlexiCopyButton} from "@flexinet/ui-components";
import {formatTimestamp} from "../../../utils/TableTimestamps.tsx";


export const usePersonasTableColumns = () => {

    const dispatch = useAppDispatch();

    const columns: DataTableColumn<Persona>[] = [
        {
            accessor: 'name',
            title: I18n.get('Name'),
            width: '1fr',
            render: (item) => <NavLink
                to={`/personas/${item.id}`}>{[item.givenName, item.familyName].join(' ')}</NavLink>,
        },
        {
            accessor: 'email',
            title: I18n.get('Email'),
            width: '1fr',
            render: (item) => <Group>{item.email}<FlexiCopyButton
                value={item.email}/></Group>,

        },
        {
            accessor: 'status',
            title: I18n.get('Status'),
            width: '1fr',
            render: (item) => item.status == 'ENABLED' ? <Badge color={'green'}>Enabled</Badge> :
                <Badge color={'red'}>Disabled</Badge>,
        },
        {
            accessor: 'created',
            title: I18n.get('Created'),
            width: '1fr',
            render: (item) => formatTimestamp(item.created as string),
        },
    ];


    // FETCH
    const handleAddTableColumn = (column: object) => {
        dispatch(addTableColumn(column));
    };

    const handleSetTableColumns = (columns: object[]) => {
        dispatch(setTableColumns(columns));
    };


    return {
        columns,
        handleAddTableColumn,
        handleSetTableColumns,
    };
};
