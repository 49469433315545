import {Contact,} from '../types';
import {PlatformPropertyCard} from "@flexinet/ui-components";
import {rbacActions} from "../../../config/rbac/actions.ts";
import {Stack} from '@mantine/core';


export const Profile = ({record}: { record: Contact }) => {

    const properties = [
        {
            label: 'Name',
            value: record.name as string,
            span: 12,
        }];

    const properties2 = [
        {
            label: 'Created By',
            value: typeof record.createdBy !== 'undefined' && record.createdBy !== null ? [record.createdBy.givenName, record.createdBy.familyName].join(' ') : 'n/a',
            rbac: rbacActions.UpdateContact,
            span: 12,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 12,
        }, {
            label: 'Managed By',
            value: typeof record.managedBy !== 'undefined' && record.managedBy !== null ? [record.managedBy.givenName, record.managedBy.familyName].join(' ') : 'n/a',
            rbac: rbacActions.UpdateContact,
            span: 12,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 12,
        }];

    return (
        <Stack gap={'xl'}>
            <PlatformPropertyCard properties={properties}/>
            <PlatformPropertyCard properties={properties2}/>
        </Stack>
    );


};

